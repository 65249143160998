import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import FormikErrorFocus from 'formik-error-focus';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import FaceIcon from '@material-ui/icons/Face';

import ActionBar from '../ActionBar';
import Card from '../Card';
import CardContent from '../CardContent';
import CardHeader from '../CardHeader';
import FieldWrapper from '../FieldWrapper';
import FormNotification from '../FormNotification';
import Notifier from '../Notifier';

import { isArrayWithLenth } from '../../utils';
import Button from '../Button';

const useStyles = makeStyles({
  form: {
    margin: '0 auto',
  },

  formInline: {
    margin: '0',
  },

  legend: {
    marginBottom: '50px',
  },

  container: {
    width: '100%',
    maxWidth: '500px',
  },
});

const Form = ({
  fields,
  initialValues,
  onSubmit,
  loading,
  validationSchema,
  header,
  subheader,
  dataTransformer,
  legend,
  noFieldMessage,
  centerHeader,
  noActions,
  error,
  success,
  errorMessage,
  renderAfterFields,
  renderBeforeFields,
  renderBeforeActions,
  noCard,
  container,
  maxWidthButton,
  inline,
  button,
  customStyles,
  ...props
}) => {
  const classes = useStyles();

  const formatAndSubmit = (values) => {
    if (!onSubmit) return;

    if (dataTransformer) {
      onSubmit(dataTransformer(values));
      return;
    }

    onSubmit({ variables: values });
  };

  const renderForm = () => {
    return (
      <Formik
        validateOnChange={false}
        initialValues={initialValues}
        onSubmit={formatAndSubmit}
        validationSchema={validationSchema && validationSchema}
        {...props}
        render={({ handleSubmit, values, errors }) => {
          return (
            <form
              onSubmit={handleSubmit}
              className={clsx({ [classes.container]: container })}
            >
              <CardContent flex={inline} {...props} style={{...customStyles}}>
                {renderBeforeFields && renderBeforeFields()}

                <div
                  className={clsx(classes.form, {
                    [classes.formInline]: inline,
                  })}
                >
                  {legend && (
                    <Typography
                      align="center"
                      variant="h5"
                      className={classes.legend}
                    >
                      {legend}
                    </Typography>
                  )}

                  {isArrayWithLenth(fields) ? (
                    fields.map((field) => (
                      <FieldWrapper
                        key={field.id}
                        values={values}
                        errors={errors}
                        inline={inline}
                        {...props}
                        {...field}
                      />
                    ))
                  ) : (
                    <>
                      {!!noFieldMessage && (
                        <FormNotification
                          label={noFieldMessage}
                          icon={<FaceIcon />}
                        />
                      )}
                    </>
                  )}
                </div>

                {!!(!!inline && !!button) && (
                  <Button type="submit" {...button}>
                    {button.label}
                  </Button>
                )}

                {renderBeforeActions && renderBeforeActions()}
              </CardContent>

              {!!(!noActions && !inline) && (
                <ActionBar
                  maxWidth={maxWidthButton}
                  disabled={loading}
                  button={button}
                  {...props}
                />
              )}

              {renderAfterFields && renderAfterFields()}

              <Notifier
                success={success}
                error={error}
                errorMessage={errorMessage}
                loading={loading}
                {...props}
              />

              <FormikErrorFocus
                offset={-200}
                align="top"
                focusDelay={0}
                duration={1}
              />
            </form>
          );
        }}
      />
    );
  };

  if (noCard) {
    return renderForm();
  }

  return (
    <Card noActions={noActions} bigShadow {...props}>
      {(header || subheader) && (
        <CardHeader
          title={header}
          subheader={subheader}
          centerHeader={centerHeader}
          smallPadding
          {...props}
        />
      )}

      {renderForm()}
    </Card>
  );
};

Form.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape({})),
  initialValues: PropTypes.shape({}),
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
  validationSchema: PropTypes.shape({}),
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  subheader: PropTypes.string,
  dataTransformer: PropTypes.func,
  legend: PropTypes.string,
  noFieldMessage: PropTypes.string,
  centerHeader: PropTypes.bool,
  noActions: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
  success: PropTypes.bool,
  errorMessage: PropTypes.string,
  renderAfterFields: PropTypes.func,
  renderBeforeFields: PropTypes.func,
  renderBeforeActions: PropTypes.func,
  noCard: PropTypes.bool,
  container: PropTypes.bool,
  maxWidthButton: PropTypes.bool,
  inline: PropTypes.bool,
  customStyles:PropTypes.shape({}),
  button: PropTypes.shape({
    label: PropTypes.string,
  }),
};

Form.defaultProps = {
  onSubmit: undefined,
  initialValues: {},
  customStyles:{},
  loading: false,
  validationSchema: null,
  header: null,
  subheader: null,
  dataTransformer: undefined,
  legend: null,
  noFieldMessage: 'No fields have been configured for this form.',
  centerHeader: false,
  fields: null,
  noActions: false,
  error: null,
  success: false,
  errorMessage: null,
  renderAfterFields: undefined,
  renderBeforeFields: undefined,
  renderBeforeActions: undefined,
  noCard: false,
  container: false,
  maxWidthButton: false,
  inline: false,
  button: null,
};

export default memo(Form);
