/**
 * @format
 * @flow
 */

import gql from 'graphql-tag';

const DEFAULT_QUERY = gql`
  query GetToken {
    accessToken @client
    refreshToken @client
    isAuthenticated @client

    menuState @client {
      main
      myFino
      settings
      marketPlace
      help
    }

    modalState @client {
      downloadPrompt
      callbackPrompt
    }

    helpState @client {
      overview
      incomejobs
      incomeuntaggedtransactions
      incomepersonaltransactions
      incomerentaltransactions
      incomerentalquestions
      incomeinvestmenttransactions
      incomeinvestmentquestions
      incomeearnertransactions
      incomeearnerquestions
      incomefreelancetransactions
      incomefreelancequestions
      expensesjobs
      expensesuntaggedtransactions
      expensespersonaltransactions
      expensesrentaltransactions
      expensesrentalquestions
      expensesfreelancetransactions
      expensesfreelancequestions
      exportTransactions
      taxRelieftaxRelieftransactions
      taxRelieftaxReliefquestions
    }

    notesOpen @client
    todoClosed @client
    checklistChatToUs @client
  }
`;

const GET_TOKEN = gql`
  query GetToken {
    accessToken @client
    refreshToken @client
  }
`;

const IS_AUTHENTICATED = gql`
  query IsAuthenticated {
    isAuthenticated @client
  }
`;

const CREATE_USER = gql`
  mutation SignUp(
    $name: String!
    $email: String!
    $password: String!
    $referralCode: String
    $acceptedTermsAt: DateTime!
  ) {
    signUp(
      name: $name
      email: $email
      password: $password
      referralCode: $referralCode
      acceptedTermsAt: $acceptedTermsAt
    ) {
      accessToken
      refreshToken
      user {
        id
        firstName
        lastName
        name
        email
        howToHelp
        firstTime
      }
    }
  }
`;

const RESEND_ACTIVATION = gql`
  mutation ResendActivation {
    resendActivation {
      success
    }
  }
`;

const RESET_PASSWORD = gql`
  mutation ResetUserPassword($email: String!) {
    resetUserPassword(email: $email) {
      hasReset
    }
  }
`;

const CHANGE_FORGOTTEN_PASSWORD = gql`
  mutation ChangeForgottenPassword(
    $email: String!
    $newPassword: String!
    $activationCode: String!
  ) {
    changeForgottenPassword(
      email: $email
      newPassword: $newPassword
      activationCode: $activationCode
    ) {
      passwordChanged
    }
  }
`;

const CREATE_TOKEN = gql`
  mutation LogIn($email: String!, $password: String!) {
    logIn(email: $email, password: $password) {
      accessToken
      refreshToken
      user {
        id
        firstName
        lastName
        name
        email
        tutorialComplete
        howToHelp
        firstTime
      }
    }
  }
`;

const REFRESH_TOKEN = gql`
  mutation ($refreshToken: String!) {
    refresh(refreshToken: $refreshToken) {
      accessToken
      refreshToken
    }
  }
`;

const SET_PIN = gql`
  mutation SetPin($pin: String!) {
    setPin(pin: $pin) {
      success
    }
  }
`;

const CHECK_PIN = gql`
  query CheckPin($pin: String!) {
    checkPin(pin: $pin)
  }
`;

const REGISTER_DEVICE = gql`
  mutation RegisterDevice(
    $make: String
    $deviceId: String
    $uniqueId: String
    $name: String!
    $oneSignalId: String!
    $pushToken: String
  ) {
    registerDevice(
      make: $make
      deviceId: $deviceId
      uniqueId: $uniqueId
      name: $name
      oneSignalId: $oneSignalId
      pushToken: $pushToken
    ) {
      id
    }
  }
`;

const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    user {
      id
      name
      firstName
      lastName
      email
      phoneNumber
      pinEnabled
      inviteCode
      inviteCodeUses
      howToHelp
      firstTime
      referralPartner {
        name
      }
      hasPaidSubscription
      activeSubscriptionName
      capabilities {
        viewSuperUserPortal
        viewCustomerPortal
        viewSubscriberOnlyContent
        viewFormerSubscriberContent
        viewFormerFreeSubscriberContent
        viewAccountantPortal
        viewBookkeeperPortal
        manageOwnBooks
        connectOwnBank
        manageOwnRules
        canSubscribeWithFreeTrial
      }
    }
  }
`;

const GET_CURRENT_USER_DETAILS = gql`
  query GetCurrentUser {
    user {
      id
      email
      name
      firstName
      lastName
      address
      city
      country
      postCode
      dateOfBirth
      phoneNumber
      nationalInsuranceNumber
      profileImage
      addressChangedInLastYear
      addressChangeDate
      refundRecievedLastYear
      refundAmount
      utrNumber
      collectThroughPayCheck
      paymentOnAccountLastYear
      paymentOnAccountLastYearWithTax
      paymentOnAccountLastYearJune
      isScottishTaxPayer
      howToHelp
      firstTime
      availableEscalations
    }
  }
`;

const GET_USER_DETAILS = gql`
  query GetUserDetails($userId: ID) {
    user(userId: $userId) {
      id
      email
      name
      firstName
      lastName
      address
      city
      country
      postCode
      dateOfBirth
      phoneNumber
      nationalInsuranceNumber
      profileImage
      addressChangedInLastYear
      addressChangeDate
      refundRecievedLastYear
      refundAmount
      utrNumber
      collectThroughPayCheck
      paymentOnAccountLastYear
      paymentOnAccountLastYearWithTax
      paymentOnAccountLastYearJune
      isScottishTaxPayer
      howToHelp
      firstTime
      availableEscalations
    }
  }
`;

const UPDATE_USER = gql`
  mutation UpdateUser(
    $userId: ID
    $firstName: String
    $lastName: String
    $address: String
    $city: String
    $country: String
    $postCode: String
    $dateOfBirth: Date
    $phoneNumber: String
    $nationalInsuranceNumber: String
    $profileImage: String
    $addressChangedInLastYear: Boolean
    $addressChangeDate: Date
    $refundRecievedLastYear: Boolean
    $refundAmount: Decimal
    $utrNumber: String
    $collectThroughPayCheck: Boolean
    $paymentOnAccountLastYear: Boolean
    $paymentOnAccountLastYearWithTax: Decimal
    $paymentOnAccountLastYearJune: Decimal
    $isScottishTaxPayer: Boolean
    $howToHelp: String
    $firstTime: Boolean
  ) {
    updateUser(
      userId: $userId
      firstName: $firstName
      lastName: $lastName
      address: $address
      city: $city
      country: $country
      postCode: $postCode
      dateOfBirth: $dateOfBirth
      phoneNumber: $phoneNumber
      nationalInsuranceNumber: $nationalInsuranceNumber
      profileImage: $profileImage
      addressChangedInLastYear: $addressChangedInLastYear
      addressChangeDate: $addressChangeDate
      refundRecievedLastYear: $refundRecievedLastYear
      refundAmount: $refundAmount
      utrNumber: $utrNumber
      collectThroughPayCheck: $collectThroughPayCheck
      paymentOnAccountLastYear: $paymentOnAccountLastYear
      paymentOnAccountLastYearWithTax: $paymentOnAccountLastYearWithTax
      paymentOnAccountLastYearJune: $paymentOnAccountLastYearJune
      isScottishTaxPayer: $isScottishTaxPayer
      howToHelp: $howToHelp
      firstTime: $firstTime
    ) {
      id
      email
      name
      firstName
      lastName
      address
      city
      country
      postCode
      dateOfBirth
      phoneNumber
      nationalInsuranceNumber
      profileImage
      addressChangedInLastYear
      addressChangeDate
      refundRecievedLastYear
      refundAmount
      paymentOnAccountLastYear
      paymentOnAccountLastYearWithTax
      paymentOnAccountLastYearJune
      isScottishTaxPayer
      howToHelp
      firstTime
      utrNumber
    }
  }
`;

const GET_META = gql`
  query GetMeta {
    user {
      id
      email
      name
      firstName
      lastName
      phoneNumber
      pinEnabled
      inviteCode
      inviteCodeUses
      howToHelp
      firstTime
      hasPaidSubscription
      activeSubscriptionName
      capabilities {
        viewSuperUserPortal
        viewCustomerPortal
        viewSubscriberOnlyContent
        viewFormerSubscriberContent
        viewFormerFreeSubscriberContent
        viewAccountantPortal
        viewBookkeeperPortal
        manageOwnBooks
        connectOwnBank
        manageOwnRules
        canSubscribeWithFreeTrial
      }
      referralPartner {
        name
      }
    }
    hmrcCategories {
      id
      displayName
      jobType {
        name
      }
    }
    jobs {
      id
      name
      jobType {
        id
        name
        incomeTag {
          id
        }
        expenseTag {
          id
        }
      }
    }
    taxYears {
      label
      labelAlt
      value
      scope
      current
      active
      defaultYear
      taxDueDate
      vatDueDate
      startDate
      endDate
      disabled
    }
    accounts {
      id
      needsAuth
    }
  }
`;

const GET_TAX_YEARS = gql`
  query GetTaxYEars {
    taxYears {
      label
      labelAlt
      value
      scope
      current
      active
      defaultYear
      taxDueDate
      vatDueDate
      startDate
      endDate
      disabled
    }
  }
`;

const GET_PREVIOUS_YEAR_SELF_ASSESSMENT = gql`
  query GetPreviousYearSelfAssessment {
    user {
      id
      email
      firstName
      lastName
      previousYearSelfAssessment {
        id
        file
        additionalNotes
        uri
      }
    }
  }
`;

const UPDATE_PREVIOUS_YEAR_SELF_ASSESSMENT = gql`
  mutation UpdatePreviousYearSelfAssessment(
    $file: Upload!
    $additionalNotes: String
  ) {
    updatePreviousYearSelfAssessment(
      file: $file
      additionalNotes: $additionalNotes
    ) {
      id
    }
  }
`;

const SEND_MOBILE_APP_PROMPT = gql`
  mutation SendMobileAppPrompt($phoneNumber: String!) {
    SendMobileAppPrompt(phoneNumber: $phoneNumber) {
      success
    }
  }
`;

export {
  GET_TOKEN,
  IS_AUTHENTICATED,
  CREATE_USER,
  RESEND_ACTIVATION,
  RESET_PASSWORD,
  CHANGE_FORGOTTEN_PASSWORD,
  CREATE_TOKEN,
  REFRESH_TOKEN,
  SET_PIN,
  CHECK_PIN,
  REGISTER_DEVICE,
  GET_CURRENT_USER,
  GET_CURRENT_USER_DETAILS,
  GET_USER_DETAILS,
  UPDATE_USER,
  GET_META,
  GET_TAX_YEARS,
  GET_PREVIOUS_YEAR_SELF_ASSESSMENT,
  UPDATE_PREVIOUS_YEAR_SELF_ASSESSMENT,
  SEND_MOBILE_APP_PROMPT,
  DEFAULT_QUERY,
};
