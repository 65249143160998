import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { Field } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import DatePicker from 'react-multi-date-picker';
import Label from '../Label';

const useStyles = makeStyles({
  dateRangeMonthPicker: {
    width: '100%',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    padding: '6px 14px',
    height: '36px',
    borderRadius: '4px',
    color: 'rgba(0, 0, 0, 0.87)',
    cursor: 'text',
    display: 'inline-flex',
    position: 'relative',
    fontSize: '1rem',
    boxSizing: 'border-box',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    letterSpacing: '0.00938em',
  },
});

const SelectMonthRangeField = ({ name, min, max, hintText, ...props }) => {
  const classes = useStyles();

  const [dateValue, setDateValue] = useState(null); 

  useEffect(() => {
    setDateValue(null);
  }, [min, max]);
  
  return (
    <Field
      name={name}
      render={({
        field,
        form: { errors, touched, submitCount, setFieldValue },
      }) => {
        const errorMessage =
          ((touched && touched[field.name]) || submitCount > 0) &&
          errors &&
          errors[field.name];

        return (
          <FormControl fullWidth error={!!errorMessage}>
            <Label {...field} {...props} />

            <DatePicker
              onlyMonthPicker
              range
              inputClass={classes.dateRangeMonthPicker}
              value={dateValue} // Use state for value
              minDate={min}
              maxDate={max}
              onChange={(selectedDate) => {
                if (Array.isArray(selectedDate)) {
                  setDateValue([selectedDate[0], selectedDate[1]]); // Update state
                  setFieldValue(field.name, [selectedDate[0], selectedDate[1]]);
                }
              }}
            />

            {errorMessage && (
              <FormHelperText error>{errorMessage}</FormHelperText>
            )}

            {hintText && <FormHelperText>{hintText}</FormHelperText>}
          </FormControl>
        );
      }}
    />
  );
};

SelectMonthRangeField.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  hintText: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  small: PropTypes.bool,
  min: PropTypes.string,
  max: PropTypes.string,
};

SelectMonthRangeField.defaultProps = {
  label: null,
  readOnly: false,
  hintText: null,
  disabled: false,
  small: false,
  min: null,
  max: null,
};

export default SelectMonthRangeField;
