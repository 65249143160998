import React from 'react';
import PropTypes from 'prop-types';

import HelpIcon from '@material-ui/icons/Help';

import SelectField from '../SelectField';
import TextField from '../TextField';
import RadioField from '../RadioField';
import FormNotification from '../FormNotification';
import IncomeField from '../IncomeField';
import FormTitle from '../FormTitle';
import TransactionField from '../TransactionField';
import PasswordField from '../PasswordField';
import InvestmentTotalField from '../InvestmentTotalField';
import JobDocumentFieldWrapper from '../JobDocumentFieldWrapper';
import FileField from '../FileField';
import ImageField from '../ImageField';
import CheckboxField from '../CheckboxField';
import DateField from '../DateField';
import TagFieldWrapper from '../TagFieldWrapper';
import InfoText from '../InfoText';
import SelectMonthRangeField from '../SelectMonthRangeField/SelectMonthRangeField';

const Field = ({
  type,
  validateOnChange,
  secondaryButton,
  validateOnBlur,
  enableReinitialize,
  ...props
}) => {
  if (type === 'text' || type === 'password') {
    return <TextField type={type} {...props} />;
  }

  if (type === 'passwordNew') {
    return <PasswordField {...props} />;
  }

  if (type === 'select') {
    return <SelectField type={type} {...props} />;
  }
  if (type === 'monthrange') {
    return <SelectMonthRangeField type={type} {...props} />;
  }

  if (type === 'radio') {
    return <RadioField type={type} {...props} />;
  }

  if (type === 'income') {
    return <IncomeField type={type} {...props} />;
  }

  if (type === 'info') {
    return <FormNotification {...props} />;
  }

  if (type === 'notification') {
    return <FormNotification icon={<HelpIcon />} note {...props} />;
  }

  if (type === 'title') {
    return <FormTitle {...props} />;
  }

  if (type === 'transaction') {
    return <TransactionField {...props} />;
  }

  if (type === 'investmentTotal') {
    return <InvestmentTotalField {...props} />;
  }

  if (type === 'job') {
    return <JobDocumentFieldWrapper {...props} />;
  }

  if (type === 'file') {
    return <FileField {...props} />;
  }

  if (type === 'image') {
    return <ImageField {...props} />;
  }

  if (type === 'checkbox') {
    return <CheckboxField {...props} />;
  }

  if (type === 'date') {
    return <DateField {...props} />;
  }

  if (type === 'tag') {
    return <TagFieldWrapper {...props} />;
  }

  if (type === 'infoText') {
    return <InfoText {...props} />;
  }

  if (type === 'space') {
    return false;
  }

  return <TextField type={type} {...props} />;
};

Field.propTypes = {
  type: PropTypes.string.isRequired,
  label: PropTypes.string,
  secondaryButton: PropTypes.shape({}),
  validateOnChange: PropTypes.bool,
  validateOnBlur: PropTypes.bool,
  enableReinitialize: PropTypes.bool,
};

Field.defaultProps = {
  label: null,
  secondaryButton: null,
  validateOnChange: false,
  validateOnBlur: false,
  enableReinitialize: false,
};

export default Field;
