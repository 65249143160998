import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@reach/router';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import ButtonStyle from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  largeCentered: {
    maxWidth: 440,
    display: 'flex',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
  },
  margin: {
    display: 'flex',
    margin: '10px 0',
    width: '100%',

    [theme.breakpoints.up('md')]: {
      display: 'inline-flex',
      margin: '0 5px',
      width: 'auto',
    },
  },
  textLink: {
    fontSize: '12px',
    lineHeight: '18px',
    textDecoration: 'underline',
    textTransform: 'none',
    marginTop: '10px',
    display: 'block',

    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  marginTop: {
    marginTop: '20px',
  },
  marginTopLarge: {
    marginTop: '30px',
  },
  maxWidth: {
    maxWidth: '440px',
  },
  alt: {
    height: '80px',
    border: '1px solid rgba(0, 0, 0, 0.2)',
    borderRadius: '3px',
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    boxShadow: 'none',
    maxWidth: '150px',
    transition: 'none',
    flex: '0 0 45%',

    '&:hover': {
      backgroundColor: theme.palette.common.white,
      border: '1px solid rgba(0, 0, 0, 0.4)',
      color: theme.palette.common.black,
      boxShadow: 'none',
    },
  },
  altActive: {
    background: ' rgba(232, 50, 135, 0.05)',
    border: '2px solid #E83287',
    color: '#E83287',
    boxShadow: 'none',

    '&:hover': {
      background: ' rgba(232, 50, 135, 0.05)',
      border: '2px solid #E83287',
      color: '#E83287',
      boxShadow: 'none',
    },
  },
  hasIcon: {
    display: 'flex',

    '& > svg': {
      margin: '0 10px 0 0',
      width: '6px',
      height: '11px',
    },
  },
  marginRight: {
    marginRight: '10px',
  },
}));

const Button = ({
  children,
  to,
  label,
  largeCentered,
  margin,
  marginTop,
  marginTopLarge,
  marginRight,
  textLink,
  maxWidth,
  alt,
  active,
  hasIcon,
  ...props
}) => {
  const classes = useStyles();
  return (
    <ButtonStyle
      className={clsx({
        [classes.largeCentered]: largeCentered,
        [classes.margin]: margin,
        [classes.marginTop]: marginTop,
        [classes.marginTopLarge]: marginTopLarge,
        [classes.textLink]: textLink,
        [classes.maxWidth]: maxWidth,
        [classes.alt]: alt,
        [classes.altActive]: alt && active,
        [classes.hasIcon]: hasIcon,
        [classes.marginRight]: marginRight,
      })}
      component={to ? Link : 'button'}
      to={to}
      {...props}
      style={{ minWidth: '184px' }}
    >
      {children || label}
    </ButtonStyle>
  );
};

Button.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string,
  label: PropTypes.string,
  largeCentered: PropTypes.bool,
  margin: PropTypes.bool,
  marginTop: PropTypes.bool,
  marginTopLarge: PropTypes.bool,
  textLink: PropTypes.bool,
  maxWidth: PropTypes.bool,
  alt: PropTypes.bool,
  active: PropTypes.bool,
  hasIcon: PropTypes.bool,
  marginRight: PropTypes.bool,
};

Button.defaultProps = {
  children: undefined,
  to: undefined,
  label: undefined,
  largeCentered: false,
  margin: false,
  marginTop: false,
  marginTopLarge: false,
  textLink: false,
  maxWidth: false,
  alt: false,
  active: false,
  hasIcon: false,
  marginRight: false,
};

export default Button;
