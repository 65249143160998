import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import { ApolloProvider } from '@apollo/react-hooks';
import { ThemeProvider } from '@material-ui/styles';
import { SnackbarProvider } from 'notistack';

import CssBaseline from '@material-ui/core/CssBaseline';
import baseTheme from './theme/base';

import Client, { persistor } from './config/Client';

import App from './App';
import * as serviceWorker from './serviceWorker';

import { isMobileDevice } from './utils';

import '@formatjs/intl-numberformat/polyfill';
import '@formatjs/intl-numberformat/locale-data/en';

const SCHEMA_VERSION = '25';
const SCHEMA_VERSION_KEY = 'FINMO_SCHEMA_VERSION';

Sentry.init({
  dsn: 'https://278f89f98ba94bff9b68b96217b3b91d@sentry.io/1824498',
}); // locale-data for en

const renderApp = async () => {
  const currentVersion = await window.localStorage.getItem(SCHEMA_VERSION_KEY);

  try {
    if (currentVersion === SCHEMA_VERSION) {
      await persistor.restore();
    } else {
      await persistor.purge();
      await window.localStorage.setItem(SCHEMA_VERSION_KEY, SCHEMA_VERSION);
    }
  } catch (err) {
    // eslint-disable-next-line
    console.error(err);
  }

  ReactDOM.render(
    <ApolloProvider client={Client}>
      <ThemeProvider theme={baseTheme}>
        <CssBaseline>
          <SnackbarProvider
            maxSnack={1}
            autoHideDuration={3000}
            anchorOrigin={{
              vertical: isMobileDevice() ? 'top' : 'bottom',
              horizontal: 'right',
            }}
          >
            <App />
          </SnackbarProvider>
        </CssBaseline>
      </ThemeProvider>
    </ApolloProvider>,
    document.getElementById('root'),
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: http://bit.ly/CRA-PWA
  serviceWorker.unregister();
};

renderApp();
