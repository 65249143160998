const getTodoItems = ({
  data,
  hasSelfAssessment,
  user,
  chatToUs,
  handleOpenDownloadModal,
  handleChatToUsClick,
}) => {
  if (!data) return [];

  const { jobs, accounts, rules } = data;

  const hasPinSet = user?.pinEnabled;
  const hasActualBank = accounts?.some((account) => account.bank.id !== '28');

  if (user?.referralPartner?.name === 'gophr') {
    return [
      {
        id: 'configureIncomeSources',
        title: 'Create income sources',
        body: 'Create, customise and rename your income sources so you can tag your transactions.',
        completed: !!jobs?.length,
        to: '/settings/income-sources',
      },

      {
        id: 'claimDiscount',
        title: 'Claim your free Membership',
        body: "We've partnered with Gopher to give you a a free yearly Finmo Membership",
        completed: user?.hasPaidSubscription,
        to: '/plans',
      },

      {
        id: 'customerSuccessManager',
        title: 'Chat with your Customer Success Managers',
        body: 'Got a question about your tax return or Finmo. Let us know!',
        completed: chatToUs,
        onClick: () => {
          window?.HubSpotConversations?.widget?.open();
          handleChatToUsClick();
        },
      },

      {
        id: 'startYourTaxReturn',
        title: 'Start your Self Assessment',
        body: 'Get started with your tax return.',
        completed: hasSelfAssessment,
        to: `/marketplace/self-assessment`,
      },
    ];
  }

  if (
    user.howToHelp === 'Self Assessment ready now' ||
    user.howToHelp === 'Self Assessment general'
  ) {
    return [
      {
        id: 'configureIncomeSources',
        title: 'Create income sources',
        body: 'Create, customise and rename your income sources so you can tag your transactions.',
        completed: !!jobs?.length,
        to: '/settings/income-sources',
      },

      {
        id: 'customerSuccessManager',
        title: 'Chat with your Customer Success Managers',
        body: 'Got a question about your tax return or Finmo. Let us know!',
        completed: chatToUs,
        onClick: () => {
          window?.HubSpotConversations?.widget?.open();
          handleChatToUsClick();
        },
      },

      {
        id: 'startYourTaxReturn',
        title: 'Start your Self Assessment',
        body: 'Get started with your tax return.',
        completed: hasSelfAssessment,
        to: `/marketplace/self-assessment`,
      },

      // {
      //   id: 'connectABank',
      //   title: 'Import your transactions',
      //   body: 'Import your transactions to manage your income and expenses.',
      //   completed: hasActualBank,
      //   to: '/settings/bank-accounts/connect',
      // },

      // {
      //   id: 'tagTransaction',
      //   title: 'Tag a Transaction',
      //   body: 'Start tagging your transactions and keep on top of your expenses.',
      //   completed: !!taxData?.taxCalculation?.manuallyCategorised,
      //   to: `/transactions`,
      // },

      // {
      //   id: 'downloadMobileAPp',
      //   title: 'Download the Mobile App',
      //   body: 'Download the Finmo Mobile app and start tagging your transactions.',
      //   completed: hasPinSet,
      //   onClick: handleOpenDownloadModal,
      // },

      // {
      //   id: 'makeARule',
      //   title: 'Make a Rule',
      //   body: 'Use the Finmo Mobile App to create rules and make tagging easier.',
      //   onClick: handleOpenDownloadModal,
      //   completed: !!rules?.length,
      // },
    ];
  }

  // if (user.howToHelp === 'Self Assessment general') {
  //   return [
  //     {
  //       id: 'configureIncomeSources',
  //       title: 'Create income sources',
  //       body: 'Create, customise and rename your income sources so you can tag your transactions.',
  //       completed: !!jobs?.length,
  //       to: '/settings/income-sources',
  //     },

  //     {
  //       id: 'completeProfile',
  //       title: 'Complete your profile',
  //       body: 'Complete your profile and general tax options.',
  //       completed: !!user?.phoneNumber,
  //       to: `/marketplace/self-assessment/get-started/overview`,
  //     },

  //     {
  //       id: 'connectABank',
  //       title: 'Import your transactions',
  //       body: 'Import your transactions to manage your income and expenses.',
  //       completed: hasActualBank,
  //       to: '/settings/bank-accounts/connect',
  //     },

  //     {
  //       id: 'tagTransaction',
  //       title: 'Tag a Transaction',
  //       body: 'Start tagging your transactions and keep on top of your expenses.',
  //       completed: !!taxData?.taxCalculation?.manuallyCategorised,
  //       to: `/transactions`,
  //     },

  //     {
  //       id: 'downloadMobileAPp',
  //       title: 'Download the Mobile App',
  //       body: 'Download the Finmo Mobile app and start tagging your transactions.',
  //       completed: hasPinSet,
  //       onClick: handleOpenDownloadModal,
  //     },

  //     {
  //       id: 'makeARule',
  //       title: 'Make a Rule',
  //       body: 'Use the Finmo Mobile App to create rules and make tagging easier.',
  //       onClick: handleOpenDownloadModal,
  //       completed: !!rules?.length,
  //     },

  //     {
  //       id: 'startYourTaxReturn',
  //       title: 'Start your Self Assessment',
  //       body: 'Get started on your tax return.',
  //       completed: hasSelfAssessment,
  //       to: `/marketplace/self-assessment`,
  //     },
  //   ];
  // }

  if (user.howToHelp === 'Better way to track') {
    return [
      {
        id: 'configureIncomeSources',
        title: 'Create income sources',
        body: 'Create, customise and rename your income sources so you can tag your transactions.',
        completed: !!jobs?.length,
        to: '/settings/income-sources',
      },

      {
        id: 'connectABank',
        title: 'Import your transactions',
        body: 'Import your transactions to manage your income and expenses.',
        completed: hasActualBank,
        to: '/settings/bank-accounts/connect',
      },

      {
        id: 'tagTransaction',
        title: 'Tag a Transaction',
        body: 'Start tagging your transactions and keep on top of your expenses.',
        completed: !!data?.taxCalculation?.manuallyCategorised,
        to: `/transactions`,
      },

      {
        id: 'downloadMobileAPp',
        title: 'Download the Mobile App',
        body: 'Download the Finmo Mobile app and start tagging your transactions.',
        completed: hasPinSet,
        onClick: handleOpenDownloadModal,
      },

      {
        id: 'makeARule',
        title: 'Make a Rule',
        body: 'Use the Finmo Mobile App to create rules and make tagging easier.',
        onClick: handleOpenDownloadModal,
        completed: !!rules?.length,
      },

      {
        id: 'customerSuccessManager',
        title: 'Looking to file your tax return?',
        body: 'Chat with your Customer Success Manager.',
        completed: chatToUs,
        onClick: () => {
          window?.HubSpotConversations?.widget?.open();
          handleChatToUsClick();
        },
      },
    ];
  }

  return [
    {
      id: 'configureIncomeSources',
      title: 'Create income sources',
      body: 'Create, customise and rename your income sources so you can tag your transactions.',
      completed: !!jobs?.length,
      to: '/settings/income-sources',
    },

    {
      id: 'connectABank',
      title: 'Import your transactions',
      body: 'Import your transactions to manage your income and expenses.',
      completed: hasActualBank,
      to: '/settings/bank-accounts/connect',
    },

    {
      id: 'tagTransaction',
      title: 'Tag a Transaction',
      body: 'Start tagging your transactions and keep on top of your expenses.',
      completed: !!data?.taxCalculation?.manuallyCategorised,
      to: `/transactions`,
    },

    {
      id: 'downloadMobileAPp',
      title: 'Download the Mobile App',
      body: 'Download the Finmo Mobile app and start tagging your transactions.',
      completed: hasPinSet,
      onClick: handleOpenDownloadModal,
    },

    {
      id: 'makeARule',
      title: 'Make a Rule',
      body: 'Use the Finmo Mobile App to create rules and make tagging easier.',
      onClick: handleOpenDownloadModal,
      completed: !!rules?.length,
    },

    {
      id: 'startYourTaxReturn',
      title: 'Start your Self Assessment',
      body: 'Get started on your tax return.',
      completed: hasSelfAssessment,
      to: `/marketplace/self-assessment`,
    },

    // {
    //   id: 'inviteAFriend',
    //   title: 'Refer a friend',
    //   body: "Invite a friend to join Finmo and we'll give you both £10.",
    //   to: '/referrals',
    //   completed: !!user?.inviteCodeUses,
    // },
  ];
};

const getTodoFromData = ({
  data,
  hasSelfAssessment,
  user,
  chatToUs,
  handleOpenDownloadModal,
  handleChatToUsClick,
}) => {
  if (!data) return [];

  const todoItems = getTodoItems({
    data,
    hasSelfAssessment,
    user,
    chatToUs,
    handleOpenDownloadModal,
    handleChatToUsClick,
  });

  const completedCount = todoItems.reduce(
    (a, item) => (item.completed ? a + 1 : a),
    0,
  );

  const nextTodo = todoItems?.find((item) => !item.completed);

  return {
    todoItems,
    completedCount,
    nextTodo,
  };
};

export default getTodoFromData;
