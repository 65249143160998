/**
 * @format
 * @flow
 */
/* eslint-disable */

import { ApolloClient, InMemoryCache } from '@apollo/client';
import { CachePersistor } from 'apollo-cache-persist';
import { HttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { RetryLink } from 'apollo-link-retry';
import { ApolloLink, Observable } from 'apollo-link';
import { navigate } from '@reach/router';
import { createUploadLink } from 'apollo-upload-client';

import { makeStyles, useTheme } from '@material-ui/core/styles';

import {
  GET_TOKEN,
  REFRESH_TOKEN,
  DEFAULT_QUERY,
  IS_AUTHENTICATED,
} from '../graph/auth';
import { isMobileDevice } from '../utils';

export const DEFAULTS = {
  accessToken: false,
  refreshToken: false,
  isAuthenticated: false,
  menuState: {
    main: !isMobileDevice(),
    myFino: false,
    settings: false,
    marketPlace: false,
    help: false,
  },
  modalState: {
    downloadPrompt: null,
    callbackPrompt: null,
  },
  helpState: {
    overview: true,
    incomejobs: true,
    incomeuntaggedtransactions: true,
    incomepersonaltransactions: true,
    incomerentaltransactions: true,
    incomerentalquestions: true,
    incomeinvestmenttransactions: true,
    incomeinvestmentquestions: true,
    incomeearnertransactions: true,
    incomeearnerquestions: true,
    incomefreelancetransactions: true,
    incomefreelancequestions: true,
    expensesjobs: true,
    expensesuntaggedtransactions: true,
    expensespersonaltransactions: true,
    expensesrentaltransactions: true,
    expensesrentalquestions: true,
    expensesfreelancetransactions: true,
    expensesfreelancequestions: true,
    exportTransactions: true,
    taxRelieftaxRelieftransactions: true,
    taxRelieftaxReliefquestions: true,
  },
  notesOpen: false,
  todoClosed: false,
  checklistChatToUs: false,
};

const cache = new InMemoryCache();

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_API_URL,
});

const retryLink = new RetryLink();

const resetAuth = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    console.log(graphQLErrors, networkError, operation, forward);
    // we need to handle the refresh token?
    if (graphQLErrors && graphQLErrors.length) {
      console.error(graphQLErrors);

      for (const err in graphQLErrors) {
        if (err.message === 'Token is blacklisted') {
          cache.reset();
        }

        if (err.message === 'Token is invalid or expired') {
          cache.reset();
        }

        if (err.message === 'User not authenticated') {
          cache.reset();
        }
      }
    }

    if (networkError) {
      console.error(networkError);

      if (networkError.response && networkError.response.status === 401) {
        console.error('401 ERROR!');

        // do we have a refresh token?
        const data = cache.readQuery({ query: GET_TOKEN });

        // attempt refresh
        if (data?.refreshToken) {
          // we have a token, refresh it
          return new Observable((observer) => {
            return client
              .mutate({
                mutation: REFRESH_TOKEN,
                variables: {
                  refreshToken: data.refreshToken,
                },
              })
              .then(async ({ data }) => {
                // If there's no token returned from refresh

                if (!data?.refresh) {
                  await client.cache.writeQuery({
                    query: IS_AUTHENTICATED,
                    data: {
                      isAuthenticated: false,
                    },
                  });

                  navigate('/');
                  return;
                }

                client.writeQuery({
                  query: GET_TOKEN,
                  data: {
                    accessToken: data?.refresh?.accessToken,
                    refreshToken: data?.refresh?.refreshToken,
                  },
                });

                // resend the last operation
                operation.setContext(({ headers = {} }) => ({
                  headers: {
                    ...headers,
                    authorization: `Bearer ${data?.refresh?.accessToken}`,
                  },
                }));
              })
              .then(() => {
                const subscriber = {
                  next: observer.next.bind(observer),
                  error: observer.error.bind(observer),
                  complete: observer.complete.bind(observer),
                };

                forward(operation).subscribe(subscriber);
              })
              .catch((err) => {
                console.error(err);

                observer.error(err);

                cache.reset();
              });
          });
        }

        client.cache.writeQuery({
          query: IS_AUTHENTICATED,
          data: {
            isAuthenticated: false,
          },
        });

        navigate('/');
      }
    }

    return false;
  },
);

const authLink = setContext((_, { headers }) => {
  try {
    const data = cache.readQuery({ query: GET_TOKEN });

    return {
      headers: {
        ...headers,
        authorization: data?.accessToken ? `Bearer ${data?.accessToken}` : null,
      },
    };
  } catch (err) {
    return headers;
  }
}).concat(resetAuth);

// $FlowFixMe
export const client = new ApolloClient({
  cache,
  link: ApolloLink.from([authLink, retryLink, httpLink]),
  resolvers: {
    Query: {
      accessToken: () => false,
      refreshToken: () => false,
    },
  },
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'ignore',
    },
    query: {
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  },
});

export const persistor = new CachePersistor({
  cache: client.cache,
  storage: window.localStorage,
  debug: true,
});

client.persistor = persistor;

client.onClearStore(() => {
  cache.writeQuery({ query: DEFAULT_QUERY, data: DEFAULTS });
});

// setup defaults
cache.writeQuery({ query: DEFAULT_QUERY, data: DEFAULTS });

export default client;
